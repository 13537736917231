var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"tw-max-w-full",attrs:{"cols":"6"}},[_c('ax-autocomplete',{attrs:{"background-color":"input","dense":"","error-messages":_vm.$v.selectedZones.$error
          ? [
              _vm.$t('general.validations.isRequired', [
                _vm.$t('general.phrases.zone') ]) ]
          : [],"items":_vm.zoneOptions,"label":_vm.$t('general.labels.selectZones'),"item-text":"name","outlined":"","required":"","multiple":"","chips":"","return-object":"","clearable":"","deletable-chips":""},on:{"blur":function($event){return _vm.$v.selectedZones.$touch()}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [(_vm.zoneOptions.length > 0)?_c('ax-select-all-list-item',{attrs:{"choices":_vm.zoneOptions},model:{value:(_vm.selectedZones),callback:function ($$v) {_vm.selectedZones=$$v},expression:"selectedZones"}}):_vm._e()]},proxy:true},{key:"no-data",fn:function(){return [_c('span',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.$t('setup.zones.allZonesAdded:notification'))+" ")])]},proxy:true}]),model:{value:(_vm.selectedZones),callback:function ($$v) {_vm.selectedZones=$$v},expression:"selectedZones"}})],1),_c('v-col',[_c('ax-role-selector',{attrs:{"label":_vm.$t('setup.roles.selectRole:label'),"required":"","error-messages":_vm.$v.selectedRole.$error
          ? [
              _vm.$t('general.validations.isRequired', [
                _vm.$t('general.phrases.role') ]) ]
          : [],"items":_vm.roleOptions},on:{"blur":function($event){return _vm.$v.selectedRole.$touch()}},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('ax-button',{attrs:{"mode":"tertiary","data-test-id":"add-zone-submit-button"},on:{"click":function($event){return _vm.handleAddZone(_vm.$v)}}},[_vm._v(_vm._s(_vm.$t('setup.zones.addZone:phrase')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }